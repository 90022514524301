import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Title } from '@superlogica/design-ui'
import { Heading } from 'components/common'
import { TileButton } from 'components/app'

import * as S from './styled'
import { useStaticQuery, graphql } from 'gatsby'

interface result {
  title: string
  uri: string
  vertical: {
    nodes: {
      name: string
    }[]
  }
}

const View: React.FC = () => {
  const {
    wp: { allInfoSuper }
  } = useStaticQuery(
    graphql`
      {
        wp {
          allInfoSuper {
            nodes {
              title
              uri
              vertical {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    `
  )

  const result = allInfoSuper.nodes.map((node: result) => {
    const vertical = node.vertical.nodes[0] // Pegando o primeiro nó de infoSuper
    return {
      title: `${vertical.name} - Edição ${node.title}`,
      url: node.uri
    }
  })

  return (
    <Block tag="section">
      <Heading>
        <Title size="display01" font="heading" color="babar0">
          InfoSuper Produto
        </Title>
      </Heading>
      <Block
        tag="article"
        paddingTop={{ xxxs: 40, md: 60 }}
        paddingBottom={{ xxxs: 40, md: 60 }}
      >
        <Container>
          <Row justify="center">
            <Col xs={12} md={10}>
              <Title
                size="display02"
                font="heading"
                color="babar900"
                marginBottom={40}
              >
                Acompanhe as últimas edições
              </Title>
              <S.List>
                {result.map(
                  ({ title, url }: { title: string; url: string }) => (
                    <S.Item key={title}>
                      <TileButton
                        vector="arrow-right"
                        href={url}
                        label={title}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="sonic500"
                      />
                    </S.Item>
                  )
                )}
              </S.List>
            </Col>
          </Row>
        </Container>
      </Block>
    </Block>
  )
}
export default View
