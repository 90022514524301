import { media, parseColor } from '@superlogica/design-ui'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SectionHero = styled.section`
  height: 200px;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar100)};
  `}
`

export const Item = styled.li``

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  row-gap: 24px;
  column-gap: 24px;

  ${media.lessThan('xs')} {
    grid-template-columns: 100%;
  }
`
